import axios from "axios";

const Api = {
    async getData(endpoint, params) {
        try {
            const response = await axios.get(endpoint, {params, withCredentials: true});
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    },

    async postData(endpoint, data, params) {
        try {
            const options = {withCredentials: true, ...params};
            await axios.post(endpoint, data, options);
            console.log(endpoint);
        } catch (error) {
            console.error(error);
        }
    },

    // Static methods for retrieving data
    gallery_refresh: () => Api.getData("/api/gallery/refresh"),
    langall: () => Api.getData("/api/lang/langall"),
    user: () => Api.getData("/api/user"),

    // Static methods for posting data
    saveadminlang: (data) => Api.postData("/api/lang/saveadminlang", data),
    deleteadminlang: (data) => Api.postData("/api/lang/deleteadminlang", data),

    // Static methods for managing galleries
    uploadimage: (data, facilityGuid) => Api.postData("/api/gallery/uploadimage", data, {params: {facilityGuid}}),
    deleteimage: (imageToDelete) => Api.postData("/api/gallery/deleteimage", null, {params: {imageToDelete}}),
    image_adddir: (facilityName) => Api.postData("/api/gallery/adddir", null, {params: {facilityName: facilityName}}),
    saveall: (gallery) => Api.postData("/api/gallery/saveall", gallery),
    deletedir: (facilityGuid) => Api.postData("/api/gallery/deletedir", null, {params: {facilityGuid}}),
    gallery_saveone: (facility) => Api.postData("/api/gallery/saveone", facility),

    // Static methods for managing facilities
    facility_uploadimagefacilities: (data, facilityGuid) => Api.postData("/api/facility/uploadimagefacilities", data, {params: {facilityGuid}}),
    facility_deleteimagefacilities: (imageToDelete) => Api.postData("/api/facility/deleteimagefacilities", null, {params: {imageToDelete}}),
    facility_adddirfacility: (facilityName) => Api.postData("/api/facility/adddirfacility", null, {params: {facilityName}}),
    facility_changefacilitytitle: (facility) => Api.postData("/api/facility/facilityUpdate", facility),
    deletedirfacility: (facilityGuid) => Api.postData("/api/facility/deletedirfacility", null, {params: {facilityGuid}}),
    saveFacilityOrder: async (facilities) => await Api.postData("/api/facility/saveFacilityOrder", facilities),


    // Static methods for managing routes
    routes_uploadimage: (data, key) => Api.postData("/api/routes/uploadimage", data, {params: {key}}),
    routes_deleteimage: (imageToDelete) => Api.postData("/api/routes/deleteimage", null, {params: {imageToDelete}}),
};

export default Api;
